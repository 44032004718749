const biddingRoutes = {
  bidding: "lote",
  biddingFilter: "filtros/lote",
  biddingList: "destaques/lote",
};

export default {
  ...biddingRoutes,
  beneficiaries: "beneficiarios",
  beneficiary: "beneficiario",
  filter: "filtros/empresa",
  faq: "padroes-de-risco",
  company: "empresa",
  companies: "busca",
  companiesHighRisk: "destaques/empresa",
  publicAgency: "orgao-publico",
  publicAgencies: "orgaos-publicos",
  superiorAgency: "orgao-superior",
  superiorAgencies: "orgaos-superiores",
  politician: "politico",
  politicians: "politicos",
  externalLinks: "links-externos",
  riskTheme: "risk-theme",
  printRiskTheme: "print-risk-theme",
};
