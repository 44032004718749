// Any new icon you need to update the ~/utils/iconsLibrary.ts file

import intUrl from "./intUrl";

export default [
  {
    id: `/home`,
    icon: "home",
    name: "Página inicial",
  },
  {
    id: `/${intUrl.filter}`,
    icon: "exclamation-circle",
    name: "Filtros de empresa",
  },
  {
    id: `/${intUrl.biddingFilter}`,
    icon: "exclamation-circle",
    name: "Filtros de lote",
  },
  {
    id: `/${intUrl.companies}`,
    icon: "building",
    name: "Busca",
  },
  {
    id: `/${intUrl.publicAgencies}`,
    icon: "landmark",
    name: "UASGs",
  },
  /*   {
      id: `/${intUrl.superiorAgencies}`,
      icon: "flag",
      name: "Órgãos superiores",
    }, */
  {
    id: `/${intUrl.politicians}`,
    icon: "user-tie",
    name: "Políticos",
  },
  {
    id: `/${intUrl.beneficiaries}`,
    icon: "hand-holding-usd",
    name: "Beneficiários",
  },
  {
    id: `/${intUrl.riskTheme}`,
    icon: "question-circle",
    name: "Temas de Risco",
  },
  {
    id: `/${intUrl.externalLinks}`,
    icon: "link",
    name: "Links externos",
  },
  {
    id: `/logoff`,
    icon: "sign-out-alt",
    name: "Desconectar-se",
  },
];

export const menuDropdown = [
  /*    {
        id: `/home`,
        icon: "home",
        name: "Página inicial",
      }, 
    {
      id: `/${intUrl.filter}`,
      icon: "exclamation-circle",
      name: "Filtros",
      dropdown: [
      ],
      isActive: (location) =>
        location?.pathname === `/${intUrl.filter}` ||
        location?.pathname === `/${intUrl.biddingFilter}`,
    },
  {
    id: `/${intUrl.filter}`,
    icon: "exclamation-circle",
    name: "Filtros de empresa",
  },
  {
    id: `/${intUrl.biddingFilter}`,
    icon: "exclamation-circle",
    name: "Filtros de lote",
  }, */
  {
    id: `/${intUrl.companies}`,
    icon: "building",
    name: "Busca",
  },
  /*   {
      id: `/${intUrl.filter}`,
      icon: "exclamation-circle",
      name: "Órgãos públicos",
      dropdown: [
      ],
      isActive: (location) =>
        location?.pathname === `/${intUrl.publicAgencies}` ||
        location?.pathname === `/${intUrl.superiorAgencies}`,
    }, */
  {
    id: `/${intUrl.publicAgencies}`,
    icon: "landmark",
    name: "UASGs",
  },
  /*  {
     id: `/${intUrl.superiorAgencies}`,
     icon: "flag",
     name: "Órgãos superiores",
   }, */
  {
    id: `/${intUrl.politicians}`,
    icon: "user-tie",
    name: "Políticos",
  },
  {
    id: `/${intUrl.beneficiaries}`,
    icon: "hand-holding-usd",
    name: "Beneficiários",
  },
  {
    id: `/${intUrl.riskTheme}`,
    icon: "question-circle",
    name: "Temas de Risco",
  },
  /*  {
   id: `/${intUrl.externalLinks}`,
   icon: "link",
   name: "Links externos",
 }, */
  {
    id: `/logoff`,
    icon: "sign-out-alt",
    name: "Desconectar-se",
  },
];
