import { LoginAPI } from "../../services/api";
import fetch from 'node-fetch';
import { LoginAuth } from "../reducers/Login";
import {
	LOGIN_ERROR,
	LOGIN_LOADING,
	LOGIN_LOGOFF,
	LOGIN_SUCCESS,
} from "../reducers/Login/constants";

const loadingState = () => ({
	type: LOGIN_LOADING,
});

const successState = (payload: LoginAuth) => ({
	type: LOGIN_SUCCESS,
	payload,
});

const errorState = (error: string) => ({
	type: LOGIN_ERROR,
	error,
});

export const logoff = (dispatch) => dispatch({ type: LOGIN_LOGOFF });

export const login = (user: string, pass: string) => {
	return async (dispatch) => {
		dispatch(loadingState());
		try {
			const response = await LoginAPI(user, pass);
			const auth = {
				auth: response.token,
				id: response.id_state,
			};
			dispatch(successState(auth));
			return Promise.resolve(auth);
		} catch (e) {
			// Login local falhou, fazer requisição para rota de autenticação da API REST
			try {
				const apiResponse = await fetch('https://sirg.cge.rj.gov.br/auth', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'AuthorizationApi': 'b78b91d3adc8e622628becf2406e64ba',
					},
					body: JSON.stringify({
						username: user,
						password: pass,
					}),
				});
				if (apiResponse.status === 200) {
					// Autenticação na API REST bem-sucedida, verificar se o usuário possui o grupo "REDECNPJ"
					const data = await apiResponse.json();
					if (data.authentication === 'successful' && data.groups.includes('SIRG')) {
						// O usuário possui o grupo "REDECNPJ", fazer login novamente na aplicação com as credenciais "admin:admin"
						const adminResponse = await LoginAPI('cgerj', 'qqj@?5BjhR71Y3i');
						const adminAuth = {
							auth: adminResponse.token,
							id: adminResponse.id_state,
						};
						dispatch(successState(adminAuth));
						return Promise.resolve(adminAuth);
					} else {
						// O usuário não possui o grupo "REDECNPJ", exibir mensagem de erro ou tomar a ação adequada
						const error = 'Permissão insuficiente.';
						dispatch(errorState(error));
						throw new Error(error);
					}
				} else {
					const apiError = await apiResponse.json();
					dispatch(errorState(apiError.error));
					throw new Error(apiError.error);
				}
			} catch (apiError) {
				dispatch(errorState(apiError));
				throw new Error(apiError);
			}
		}
	};
};


/* export const login = (user: string, pass: string) => {
	return async (dispatch) => {
		dispatch(loadingState());
		try {
			const response = await LoginAPI(user, pass);
			const auth = {
				auth: response.token,
				id: response.id_state,
			};
			dispatch(successState(auth));
			return Promise.resolve(auth);
		} catch (e) {
			dispatch(errorState(e));
			return Promise.reject(e);
		}
	};
}; */