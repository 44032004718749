/* eslint-disable import/named */
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import externalLinks from "../data/externalLinks";

const Grid = styled.div` 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
`

const HeightLink = styled.a.attrs({
  className: "bg-white d-flex flex-column justify-content-center p-4 rounded",
})`
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: background;

  .link__icon,
  .link__name {
    transition: 0.1s ease-in-out color;
  }
  .link__name {
    color: black;
  }

  &:hover,
  &:active {
    text-decoration: none;
    background: linear-gradient(46deg, #005A92 0%, #044c8d 100%);

    p {
      color: #fff !important;
    }

    .link__icon,
    .link__name {
      color: white;
    }
  }
`;

function Home() {
  return (
    <MainTemplate withoutContainer>
      <Container fluid className="p-0">
        <Grid>
          {externalLinks.map(({ name, id, icon, description }) => (
            <HeightLink href={id} target='_blank' key={id}>
              {icon && (
                <span className="h4 m-0 link__icon">
                  <FontAwesomeIcon icon={icon as IconName} />
                </span>
              )}
              <span className="h5 font-weight-semibold m-0 link__name">
                {name}
              </span>
              {description && (
                <p style={{ fontSize: '14px', color: '#808080', marginBottom: 0 }}>{description}</p>
              )}
            </HeightLink>
          ))}
        </Grid>
      </Container>
    </MainTemplate>
  );
}

export default Home;
