import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";

// import logo from "../assets/images/logo.svg";
import { login } from "../store/actions/LoginActions";
import { RootReducers } from "../store/reducers";
import logo from "../assets/images/logo-cge-colorida.png";

const schema = yup.object({
	user: yup.string().required(),
	password: yup.string().required(),
});

const redirection = "/home";

const Gradient = styled.div`
	background: linear-gradient(90deg, #005A92 -8.05%, #044c8d 100%);
`

const Divider = styled.div`
	background: rgb(225, 229, 237);
	height: 4px;
	width: 100%;
`;

const renderError = (error: Error): string => {
	console.dir(error);
	switch (error.message) {
		case "Unauthorized":
			return "Usuário e/ou senha incorretos";
		default:
			return error.message;
	}
};

function Index() {
	const { dispatch } = useStore();
	const history = useHistory();
	const [error, setError] = useState<Error>();
	const { loading, data: loginData } = useSelector(
		(state: RootReducers) => state.LoginReducer
	);

	const onSubmit = async ({
		user,
		password,
	}: {
		user: string;
		password: string;
	}) => {
		if (error) {
			setError(undefined);
		}
		try {
			await login(user, password)(dispatch);
			history.push(redirection);
		} catch (e) {
			setError(e);
		}
	};

	if (loginData?.auth) {
		history.push(redirection);
	}

	return (
		<div className="d-flex flex-column align-items-center justify-content-center h-100">
			<div className="fixed-top d-flex justify-content-start">
				<img src={logo} alt="SIRG" style={{ height: "70px" }} />
			</div>
			<div className="container" style={{ maxWidth: 450 }}>

				<Gradient className="p-4 rounded-top d-flex align-items-center justify-content-center" />

				<Divider />
				<div className="px-5 py-4 pb-5 bg-white rounded-bottom">
					<h1 style={{ color: '#005A92' }} className="mb-0 text-center font-weight-bold">SIRG</h1>
					{/* <p className="text-center" style={{ color: '#808080' }}>Faça seu login para continuar</p> */}
					<Formik
						validationSchema={schema}
						onSubmit={onSubmit}
						initialValues={{ user: "", password: "" }}
					>
						{({ handleSubmit, handleChange, values }) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group controlId="user">
									<Form.Label className="h7 font-weight-semibold">
										Usuário
									</Form.Label>
									<Form.Control
										required
										type="text"
										value={values.user}
										onChange={handleChange}
										placeholder="Digite seu usuário"
									/>
								</Form.Group>
								<Form.Group controlId="password">
									<Form.Label className="h7 font-weight-semibold">
										Senha
									</Form.Label>
									<Form.Control
										required
										type="password"
										value={values.password}
										onChange={handleChange}
										placeholder="Digite sua senha"
									/>
								</Form.Group>
								{loading && (
									<p className="m-0 mt-4 text-secondary">Carregando</p>
								)}
								{error && (
									<p className="m-0 mt-4 text-danger">{renderError(error)}</p>
								)}

								<div className="d-flex justify-content-center pt-4">
									<Button
										className="gradient-button h8 py-2 px-5 h8"
										type="submit"
									>
										Login
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default Index;
