import React from "react";
// eslint-disable-next-line import/named
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import styled, { css } from "styled-components";

import { MenuItem } from "./Header.interface";
import HeaderDropdownItem from "./HeaderDropdownItem";
import { defaultSidebarSize } from "../../contexts/SidebarContext";

type ThemeProps = {
  isClosed: boolean;
  defaultSidebarSize: typeof defaultSidebarSize;
};

export type SidebarThemeProps = {
  theme: ThemeProps;
};

export const SidebarLink = styled(NavLink).attrs({
  className: "h7 d-flex align-items-center",
}) <{ dropdown?: boolean }>`
  opacity: 1;
  line-height: 1;
  min-height: 36px;
  padding: 0 10px;
  position: relative;
  color: #dadada;
  border-bottom: 4px solid #044c8d;

  &:hover {
    color: white;
    text-decoration: none;
  }

  &.active {
    opacity: 1;
    color: white;
    border-bottom-color: ${props => props.dropdown ? 'transparent' : '#7cbeff'};
    flex: 1;
  }
`;

export const SidebarIconContainer = styled.span`
  width: 20px;
  font-size: 20px;
`;

export const SidebarText = styled.span.attrs({
  className: "pl-3",
})(
  ({ theme: { isClosed } }: SidebarThemeProps) => css`
    transition: 0.2s ease-in-out opacity;
    opacity: ${isClosed ? 0 : 1};
    white-space: nowrap;
  `
);

const HeaderItem = (menu: MenuItem) => {
  if (menu.dropdown) {
    return <HeaderDropdownItem menu={menu} />;
  }

  const { id, icon, name } = menu;

  return (
    <li className="d-flex">
      <SidebarLink to={id} activeClassName="active">
        <SidebarIconContainer>
          <FontAwesomeIcon icon={icon as IconName} />
        </SidebarIconContainer>
        <SidebarText>{name}</SidebarText>
      </SidebarLink>
    </li>
  );
};

export default HeaderItem;
