import React from "react";

import styled from "styled-components";
import { colors } from "../../styles/colors";

const { white } = colors;

export const BadgeContainer = styled.div<{
  color: string;
  size: number;
  content: string | number | React.ReactNode;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  height: 21px;
  width: fit-content;
  height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  background-color: ${({ color }) => color};
  border-radius: ${({ size }) => `${size}px`};
  color: ${white};
  box-shadow: ${({ color, content }) => !!content && `0px 0px 5px ${color}`};
  > span {
    padding: 0px 3px;
  }
`;

export type BadgeProps = {
  color: string;
  content: string | number | React.ReactNode;
  size: number;
  className: string | null;
  style: Record<string, unknown> | null;
};

const Badge = ({
  content,
  style,
  className,
  size = 25,
  color = white,
}: BadgeProps) => (
  <BadgeContainer
    style={style}
    size={size}
    className={className}
    color={color}
    content={content}
  >
    {content && <span>{content}</span>}
  </BadgeContainer>
);

export default Badge;
