import React, { useContext, useMemo, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { menuDropdown } from "../../../data/menu";
import {
  SidebarContext,
  defaultSidebarSize,
} from "../../contexts/SidebarContext";
import logo from "../../../assets/images/logo-cge-branca.png";
import HeaderItem from "./HeaderItem";
import styled, { css } from "styled-components";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { SidebarLogoContainer } from "./Header.styles";

export interface SidebarTheme {
  theme: { isClosed: boolean; defaultSidebarSize: typeof defaultSidebarSize };
}

export const SiderbarMenu = styled.nav.attrs({
  className: "position-fixed no-print",
})(({ theme }: SidebarTheme) => css`
  background-color: #044c8d;
  width: 100%;
  display: flex;
  z-index: 9999;
  height: 70px;

  img {
    height: 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;

    img {
      margin: 10px;
    }
  }
`);

export const SidebarUl = styled.ul`
  list-style: none;
  padding: 0;
  overflow: hidden;
  width: 100%;
  display: flex;
  margin-bottom: 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Sidebar = () => {
  const { isClosed, defaultSidebarSize } = useContext(SidebarContext);
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const items = useMemo(
    () => menuDropdown.map((menu) => <HeaderItem key={menu.id} {...menu} />),
    []
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    setIsMobile(window.innerWidth <= 768);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const handleHideMenu = () => {
    setShowMenu(false);
  };

  const showMenuIcon: IconName = "bars";
  const hideMenuIcon: IconName = "times";

  return (
    <ThemeProvider theme={{ isClosed, defaultSidebarSize }}>
      <SiderbarMenu>
        <SidebarLogoContainer>
          <a href="/home">
            <img src={logo} alt="SIRG" />
          </a>
        </SidebarLogoContainer>
        {isMobile && !showMenu ? (
          <span onClick={handleShowMenu} style={{ backgroundColor: "#044c8d", margin: 0, padding: "2px 0", display: "block", textAlign: "center" }}>
            <FontAwesomeIcon icon={faBars} color="#fff" />
          </span>
        ) : (
          <>
            {isMobile && showMenu && (
              <span onClick={handleHideMenu} style={{ backgroundColor: "#044c8d", margin: 0, padding: "2px 0", display: "block", textAlign: "center" }}>
                <FontAwesomeIcon icon={hideMenuIcon} color="#fff" />
              </span>
            )}
            <SidebarUl>{items}</SidebarUl>
          </>
        )}
      </SiderbarMenu>
    </ThemeProvider>
  );
};

export default Sidebar;