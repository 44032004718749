import React from "react";
import { MenuItem } from "./Header.interface";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import { Flex } from "rebass";

import {
  SidebarIconContainer,
  SidebarLink,
  SidebarText,
  SidebarThemeProps,
} from "./HeaderItem";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";

const HeaderLiDropdown = styled.li<{ isActive: boolean }>`
  display: flex;
  opacity: 1;
  color: #dadada;
  border-bottom: 4px solid
    ${(props) => (props.isActive ? "#7cbeff" : "#044c8d")};

  ul {
    display: none;
    position: absolute;
  }

  &:hover {
    color: white;
    text-decoration: none;

    ul {
      display: flex;
    }
  }
`;

export const SidebarTextDropdown = styled.span.attrs({
  className: "pl-3 h7",
})(
  ({ theme: { isClosed } }: SidebarThemeProps) => css`
    transition: 0.2s ease-in-out opacity;
    opacity: ${isClosed ? 0 : 1};
    white-space: nowrap;
  `
);

export const SidebarUlDropdown = styled.ul`
  list-style: none;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  top: 70px;

  li {
    background: #044c8d;
  }
`;

type HeaderDropdownItemProps = {
  menu: MenuItem;
};

const HeaderDropdownItem = ({ menu }: HeaderDropdownItemProps) => {
  const location = useLocation();

  if (!menu) {
    return null;
  }

  const { name, icon, dropdown } = menu;

  const isActive = menu.isActive(location);

  return (
    <HeaderLiDropdown isActive={isActive}>
      <Flex alignItems={"center"} p={"0px 10px"}>
        <SidebarIconContainer>
          <FontAwesomeIcon icon={icon as IconName} />
        </SidebarIconContainer>
        <SidebarTextDropdown>{name}</SidebarTextDropdown>
      </Flex>

      <SidebarUlDropdown>
        {dropdown.map((m) => (
          <li className="d-flex">
            <SidebarLink to={m.id} activeClassName="active" dropdown>
              <SidebarIconContainer>
                <FontAwesomeIcon icon={m.icon as IconName} />
              </SidebarIconContainer>
              <SidebarText>{m.name}</SidebarText>
            </SidebarLink>
          </li>
        ))}
      </SidebarUlDropdown>
    </HeaderLiDropdown>
  );
};

export default HeaderDropdownItem;
